<template>
  <section v-if="isPermission('location-images.list')">
    <ListingLayout
      :sub-screen="1"
      :is-loading="isLoading"
      :meta="meta"
      :headers="_headers"
      :items="list"
      no-filters
      :filter-names="filterNames"
      @filterOption="onFiltersChange"
    >
      <template v-slot:actions="{ item }">
        <v-icon
          v-if="$admin.can('location-images.edit')"
          class="mr-2"
          small
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          v-if="$admin.can('location-images.delete')"
          class="mr-2"
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:image_path="{ item }">
        <div class="py-1">
          <v-img
            lazy-src="https://picsum.photos/id/11/10/6"
            max-height="50"
            max-width="50"
            :src="item.image_path"
          />
        </div>
      </template>
      <!-- add Filters -->
      <!-- <template #addFilters>
        <v-card v-if="authUser.isAdmin">
          
        </v-card>
      </template> -->
      <!-- add Filters -->

      <!-- Action Modals -->
      <template #actionModals>
        <div>
          <confirmation-modal
            v-if="$admin.can('location-images.delete')"
            :is-visible="deleteModal"
            :toggle="toggleDeleteModal"
            :row="selectedRow"
          />
        </div>
      </template>
      <!-- Action Modals -->
    </ListingLayout>
  </section>
</template>

<script>
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { mapGetters } from "vuex";
// import { debounce } from "lodash";
export default {
  components: {
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    ConfirmationModal: () =>
      import("@/components/Modals/ConfirmationModal.vue"),
  },
  mixins: [ListingLayoutMixins],
  props: {
    row: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      filters: {},
      subFilters: {},

      headers: [
        {
          text: this.$t("id"),
          align: "left",
          sortable: true,
          value: "id",
        },
        {
          text: this.$t("image"),
          align: "left",
          sortable: false,
          value: "image_path",
        },
        ...(this.$admin.hasAccessTo("location-images.delete") ||
        this.$admin.hasAccessTo("location-images.edit")
          ? [
              {
                text: this.$t("actions"),
                align: "left",
                sortable: false,
                value: "actions",
              },
            ]
          : []),
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "csmsLocations/getSubLoading",
      meta: "csmsLocations/getLocMeta",
      list: "csmsLocations/getLocImgList",
    }),
    _headers() {
      // computed headers for showing columns to role based
      return this.headers.filter(
        (header) =>
          header.showAdmin === undefined ||
          (header.showAdmin != undefined &&
            header.showAdmin === this.authUser.isAdmin)
      );
    },

    filterNames() {
      return [];
    },
  },
  watch: {
    options() {
      this.fetchData();
    },
  },
  // beforeDestroy() {
  //   const params = {
  //     name: "md_makes",
  //     filters: this.options,
  //   };
  //   this.$store.commit("redirect/SET_FILTERS", params);
  // },
  methods: {
    isPermission(val) {
      return this.$admin.can(val);
    },
    async fetchData() {
      const params = {
        ...this.options,
        location_id: this.row.id,
      };
      await this.$store
        .dispatch("csmsLocations/locationImgList", params)
        .then(() => {
          if (this.options.page > (this.meta?.lastPage || 1)) {
            this.options.page = 1;
          }
        });
    },

    // Confirmation of Delete Row
    async deleteSelectedRow(row) {
      try {
        await this.$store.dispatch("csmsLocations/destroyLocationImage", row);
        this.$store.dispatch("alerts/success", this.$t("deleted_successfully"));
        this.toggleDeleteModal("close");
      } catch (error) {
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },
  },
};
</script>
